<template>
  <div v-if="visibleModalSignatureRequired" class="modal">
    <div class="modal-dialog">
      <div class="modal-content">
        <button class="close-button" @click="closeModalSignatureRequired">X</button>
        <div class="modal-image">
          <img
            src="../assets/document-assign.png"
            alt="logo-attention-specialist"
          />
        </div>
        <h2 class="modal-title text-font-attention">Verificação de assinatura digital.</h2>
        <p class="modal-text text-font-regular">
          É necessário realizar uma nova verificação da sua assinatura digital. Por favor, insira o seu PIN de Certificação Digital no campo abaixos.
        </p>
        <div>          
          <input type="text" class="rounded-input" v-model="pin" placeholder="Digite aqui o seu PIN" @input="validateInput('pin', $event)" maxlength="6">
        </div>
        <div>
          <div v-if="isLoading === true">
            <p class="text-font-regular" style="text-align: center; margin-top: 20px;">
              Autenticando...
            </p>
          </div>
          <div v-else>
            <button class="btn-back-laudo attentive-buttons" @click="validToken">
              ENTRAR
            </button>
            <button class="btn-back-queue-laudo attentive-buttons" @click="closeModalSignatureRequired">
              VOLTAR
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  props: {
    visibleModalSignatureRequired: Boolean,
  },
  computed: {
      ...mapGetters({ 
            Report: "StateReport", 
            User: "StateUser",
            UrlReport: "StateUrlReport"
        }),
    },
  data() {
    return {
      pin: null,
      isLoading: false
    };
  },
  async mounted() {  
    this.isLoading = false;  
  },
  methods: {
    ...mapGetters(["isAuthenticated"]),   
    ...mapActions(["SendPin"]), 
    closeModalSignatureRequired() {      
      this.$emit("close");
    },
    validateInput(fieldName, event) {
      const regex = /\D/g;
      const correctedValue = event.target.value.replace(regex, '');
      event.target.value = correctedValue
      this[fieldName] = correctedValue;
    },
    async validToken(){
      try{              
        if(this.pin == null || this.pin == '' || this.pin.length < 6){
          this.$toast.error('Preencha o PIN - 6 dígitos numéricos.', {
            timeout: 3000,
            closeOnClick: true
          });
        }
        else{
          this.isLoading = true;
          await this.SendPin(this.pin)
          this.$toast.success('Pin Atualizado.', {
            timeout: 3000,
            closeOnClick: true
          }); 
          this.$emit("close");
          this.isLoading = false;
        }
      }
      catch (erro) {        
        if (erro.message === '400') {
          this.$toast.warning('Pin Incorreto.', {
            timeout: 3000,
            closeOnClick: true
          }); 
          this.isLoading = false;               
        }
        else{
          this.$toast.error('Ocorreu um erro, tente novamente.', {
            timeout: 3000,
            closeOnClick: true
          });
          this.isLoading = false;
        }
      }
    }
  },
};
</script>

<style>
.rounded-input {
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 8px 12px;
    font-size: 16px;
    width: 300px;
    outline: none;
  }
  
.btn-back-queue-laudo  {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #6200EE;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border: 1px solid #6200ee;
  border-radius: 20px;
  padding: 10px;
  width: 100px;
  cursor: pointer;
  margin: 0 auto;
}

.btn-back-laudo {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #ffff;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #6200ee;
  border: 1px solid #6200ee;
  border-radius: 20px;
  padding: 10px;
  width: 300px;
  cursor: pointer;
  margin: 0 auto;
}

.attentive-buttons {
  margin-top: 25px;
  display: flex;
  justify-content: center;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-dialog {
  background-color: #fff;
  width: 500px;
  max-width: 100%;
  border-radius: 20px;
}

.modal-content {
  background-color: #fff;
  border-radius: 10px;
  max-height: 90%;
  overflow: auto;
  padding: 30px;
  border: none;
  text-align: center;
}

.modal-image {
  margin-bottom: 20px;
}

.modal-image img {
  max-width: 80px;
  max-height: 80px;
}

.modal-title {
  font-size: 24px;
  margin-top: 0;
}

.modal-text {
  font-size: 18px;
  margin-bottom: 20px;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 24px;
  color: #6200ee;
  cursor: pointer;
}

.text-font-attention {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #6200ee;
}

.text-font-regular {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  color: #777777;
}

.align-text {
  text-align: center;
}
</style>
