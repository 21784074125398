<template>
  <div v-if="visibleModalChangePhoto" class="modal">
    <div class="modal-dialog" id="modalPhoto">
      <div class="modal-content">
        <button class="close-button" @click="closeModalChangePhoto" style="color: #6200ee;">X</button>

        <div v-if="initalPage">
          <div class="modal-image">
            <img src="../assets/ic_cam.png" alt="ic_cam" />
          </div>
          <h2 class="modal-title text-font-attention">Alterar foto do perfil</h2>
          <div class="modal-buttons" style="margin-top: 15px;">
            <button class="btn-upload-save" @click="openImagePicker">
              ADICIONAR FOTO DE PERFIL
            </button>
          </div>
        </div>

        <input ref="fileInput" type="file" accept="image/png, image/jpeg" style="display: none"
          @change="handleFileChange">
        <div v-if="showCropper" style="margin-top: 15px;">
          <cropper class="cropper" v-if="showCropper" :src="cropperSrc" @change="change" ref="cropper" :auto-zoom="true"
            :stencil-size="{
              width: 300,
              height: 300
            }" image-restriction="stencil" />
          <button class="btn-upload" style="margin-top: 15px;" @click="crop">
            SALVAR
          </button>
          <button class="btn-upload-save" style="margin-top: 15px;" @click="openImagePicker">
            CARREGAR OUTRA FOTO DE PERFIL
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';

export default {
  props: {
    visibleModalChangePhoto: Boolean,
  },
  components: {
    Cropper,
  },
  data() {
    return {
      showCropper: false,
      cropperSrc: '',
      coordinates: {
        width: 0,
        height: 0,
        left: 0,
        top: 0,
      },
      image: null,
      initalPage: true
    };
  },

  methods: {
    ...mapActions(["SendImage"]),

    async crop() {
      const { canvas } = this.$refs.cropper.getResult();
      if (canvas) {
        try {
          await this.SendImage(canvas)
          this.image = null
          this.showCropper = false;
          this.cropperSrc = '';
          this.cropper = null;
          this.$emit('acao-concluida');
        }
        catch {
          this.$toast.error('Ocorreu um erro, tente novamente.', {
            timeout: 3000,
            closeOnClick: true
          });
        }
      }
    },

    openImagePicker() {
      this.initalPage = false;
      this.$refs.fileInput.click();
    },

    handleFileChange(event) {
      this.cropperSrc = '';
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.cropperSrc = e.target.result;
          this.showCropper = true;
        };
        reader.readAsDataURL(file);
      }
    },

    onCropperReady(cropper) {
      this.cropper = cropper;
    },
    onCropEnd() {
    },

    closeModalChangePhoto() {
      this.image = null
      this.showCropper = false;
      this.cropperSrc = '';
      this.cropper = null;
      this.initalPage = true;
      this.$emit("close");
    },
  },
};
</script>

<style>
.cropper {
  height: 350px;
  width: 450px;
  background: #DDD;
}

.btn-upload-save {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #6200ee;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border: 1px solid #6200ee;
  border-radius: 20px;
  padding: 10px;
  width: 300px;
  cursor: pointer;
  margin: 0 auto;
}

.btn-upload {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #6200ee;
  border: 1px solid #6200ee;
  border-radius: 20px;
  padding: 10px;
  width: 300px;
  cursor: pointer;
  margin: 0 auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-dialog {
  background-color: #fff;
  width: 600px;
  border-radius: 20px;
}

.modal-content {
  background-color: #fff;
  border-radius: 10px;
  max-height: 90%;
  overflow: auto;
  padding: 30px;
  border: none;
  text-align: center;
}

.modal-image {
  margin-bottom: 20px;
}

.modal-image img {
  max-width: 80px;
  max-height: 80px;
}

.modal-title {
  font-size: 24px;
  margin-top: 0;
}

.modal-text {
  font-size: 18px;
  margin-bottom: 20px;
}

.modal-buttons {
  display: flex;
  justify-content: center;
}

.modal-button {
  background-color: #3498db;
  color: #fff;
  border: none;
  border-radius: 10px;
  padding: 10px 20px;
  margin-top: 20px;
  font-size: 18px;
  cursor: pointer;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 24px;
  color: #666666;
  cursor: pointer;
}

.text-font-attention {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #6200ee;
}

.text-font-regular {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  color: #777777;
}

.align-text {
  text-align: center;
}
</style>
