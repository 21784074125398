<template>
  <div v-if="visibleModalTransfer" class="modal">
    <div class="modal-dialog">
      <div class="modal-content">
        <button class="close-button" @click="closeModalTransfer">X</button>
        <h2 class="modal-title text-font-attention">Selecione um especialista para transferir o caso:</h2>

        <div class="checkbox-container">
          <div class="item-list">
            <div v-for="specialistActive in SpecialistActives" v-bind:key="specialistActive.id" class="item">
              <div v-if="specialistActive.enable === 1">
                <label class="checkbox-label">
                  <input type="radio" class="checkbox-input" :value="specialistActive.id" v-model="pickedSpecialist">
                  <span class="checkbox-custom"></span>
                  <div class="text-container">
                    <span class="checkbox-text">{{ specialistActive.name }} - {{ specialistActive.professional_code }} - {{ specialistActive.state_code }}</span>
                    <span class="subtext">{{ specialistActive.specialty }}</span>
                  </div>
                </label>
              </div>             
            </div>
          </div>
        </div>
        <div>
          <button class="btn-back-laudo attentive-buttons" @click="closeModalTransfer">
            TRANSFERIR
          </button>
          <button class="btn-back-queue-laudo attentive-buttons" @click="closeModalTransfer">
            VOLTAR
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  props: {
    visibleModalTransfer: Boolean,
  },
  computed: {
    ...mapGetters({ SpecialistActives: "StateSpecialistsActives" }),
  },
  async created() {
    await this.GetSpecialistActives();
  },
  data() {
    return {
      pickedSpecialist: 0,
    };
  },
  methods: {
    ...mapActions(["GetSpecialistActives"]),
    closeModalTransfer() {
      this.$emit("close");
      
    },
  },
};
</script>

<style>
.btn-back-queue-laudo  {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #6200EE;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border: 1px solid #6200ee;
  border-radius: 20px;
  padding: 10px;
  width: 100px;
  cursor: pointer;
  margin: 0 auto;
}

.btn-back-laudo {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #ffff;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #6200ee;
  border: 1px solid #6200ee;
  border-radius: 20px;
  padding: 10px;
  width: 300px;
  cursor: pointer;
  margin: 0 auto;
}

.attentive-buttons {
  margin-top: 25px;
  display: flex;
  justify-content: center;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-dialog {
  background-color: #fff;
  width: 500px;
  max-width: 100%;
  border-radius: 20px;
}

.modal-content {
  background-color: #fff;
  border-radius: 10px;
  max-height: 90%;
  overflow: auto;
  padding: 30px;
  border: none;
  text-align: center;
}

.modal-image {
  margin-bottom: 20px;
}

.modal-image img {
  max-width: 80px;
  max-height: 80px;
}

.modal-title {
  font-size: 24px;
  margin-top: 0;
}

.modal-text {
  font-size: 18px;
  margin-bottom: 20px;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 24px;
  color: #666666;
  cursor: pointer;
}

.text-font-attention {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #6200ee;
}

.text-font-regular {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  color: #777777;
}

.align-text {
  text-align: center;
}

.checkbox-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.item-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.checkbox-label {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.checkbox-input {
  display: none;
}

.checkbox-custom {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #6200ee;
  margin-right: 10px;
  position: relative;
}

.checkbox-custom-unable {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #E3E3E3;
  margin-right: 10px;
  position: relative;
}

.checkbox-input:checked + .checkbox-custom:after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #6200ee;
}

.text-container {
  display: flex;
  flex-direction: column;
}

.checkbox-text {
  color: #37E105;
}

.checkbox-text-unable {
  color: #E3E3E3;
}

.subtext {
  color: #999999;
  text-align: center;
}

.subtext-unable {
  color: #E3E3E3;
  text-align: center;
}

</style>
