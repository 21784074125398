export default {
    user: null,
    protocols: null,
    reports: null,    
    reportsUnsigned: null,
    protocol: null,
    exams: null,
    diagnosticHypothesis: null,
    outcomes: null,
    report: null,
    specialistsActives: null,
    enable: null,
    urlReport: null,
    urlFile: null,
    reportFields: null,
    tokenMemed: null,
    isModalSignatureRequiredOpen: false,
    form: {
        error: null
    },
    version: '4.000',    
}