<template>
  <div v-if="visibleModalReturn" class="modal">
    <div class="modal-dialog">
      <div class="modal-content">
        <button class="close-button" @click="closeModalReturnCase">X</button>
        <div class="modal-image">
          <img src="../assets/return-case.png" alt="logo-return" />
        </div>
        <h2 class="modal-title text-font-attention">Confirmação de navegação</h2>
        <p class="modal-text text-font-regular"><strong>Tem certeza de que deseja devolver o caso para a fila?</strong>
          Lembre-se de que as informações preenchidas serão perdidas.
        </p>
        <select class="form-control" v-model="selectedReason">
          <option value="">Selecione o motivo da devolução</option>
          <option value="Troca de plantão">Troca de plantão</option>
          <option value="Encaminhamento interno para outro especialista">Encaminhamento interno para outro especialista
          </option>
          <option value="Outro">Outro</option>
        </select>
        <input class="form-control text-form-pep" v-if="selectedReason === 'Outro'" style="margin-top: 10px;"
          v-model="otherReason" type="text" placeholder="Escreva aqui o motivo">
        <div>
          <button class="btn-back-return attentive-buttons" @click="backProtocols">
            CONFIRMAR
          </button>
          <button class="btn-back-queue-return attentive-buttons" @click="closeModalReturnCase">
            VOLTAR
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  props: {
    visibleModalReturn: Boolean
  },
  computed: {
    ...mapGetters({ Report: "StateReport" }),
  },
  data() {
    return {
      selectedReason: '',
      otherReason: ''
    }
  },
  methods: {
    ...mapActions(["DeleteReport"]),
    closeModalReturnCase() {
      this.$emit("close");
    },
    async backProtocols() {
      try {
        if (this.selectedReason == '') {
          this.$toast.error('Selecione o motivo da devolução.', {
            timeout: 3000,
            closeOnClick: true
          });
        }
        else {
          this.Report["reason"] = this.selectedReason === "Outro" ? this.otherReason : this.selectedReason;          
          await this.DeleteReport(this.Report)
          this.$router.push('/protocols');
        }
      }
      catch (erro) {
        this.$toast.error('Ocorreu um erro, tente novamente.', {
          timeout: 3000,
          closeOnClick: true
        });
      }
    },
  },
};
</script>

<style>
.btn-back-queue-return {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #6200EE;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border: 1px solid #6200ee;
  border-radius: 20px;
  padding: 10px;
  width: 150px;
  cursor: pointer;
  margin: 0 auto;
}

.btn-back-return {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #ffff;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #6200ee;
  border: 1px solid #6200ee;
  border-radius: 20px;
  padding: 10px;
  width: 150px;
  cursor: pointer;
  margin: 0 auto;
}

.attentive-buttons {
  margin-top: 25px;
  display: flex;
  justify-content: center;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-dialog {
  background-color: #fff;
  width: 500px;
  max-width: 100%;
  border-radius: 20px;
}

.modal-content {
  background-color: #fff;
  border-radius: 10px;
  max-height: 90%;
  overflow: auto;
  padding: 30px;
  border: none;
  text-align: center;
}

.modal-image {
  margin-bottom: 20px;
}

.modal-image img {
  max-width: 80px;
  max-height: 80px;
}

.modal-title {
  font-size: 24px;
  margin-top: 0;
}

.modal-text {
  font-size: 18px;
  margin-bottom: 20px;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 24px;
  color: #666666;
  cursor: pointer;
}

.text-font-attention {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #6200ee;
}

.text-font-regular {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  color: #777777;
}

.align-text {
  text-align: center;
}
</style>
