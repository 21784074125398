<template>
  <div class="floating-button" @click="showModalInfoBot(Report)">
    <img src="../assets/bot-svgrepo-com-white.png" alt="Imagem" class="button-image">
  </div>
  <ModalInfoBot :visibleModalInfoBot="isModalInfoBotVisible" @close="closeModalInfoBot" :protocolInfoPopup="protocolInfo"
    :enableButton="false"> </ModalInfoBot>
  <ModalLoadingSignatureHistoric :visibleModalLoadingSignature="isModalLoadingSignature" :typeSteps="typeSteps"
    @close="closeModalLoadingSignature"></ModalLoadingSignatureHistoric>
  <div class="col-12">
    <div class="mt-5 mb-5">
      <div class="row">
        <div class="col-2">
          <button class="button-back mx-3" @click="backReport()">
            <img src="../assets/back-button.png" alt="Button Image">
          </button>
        </div>
        <div class="col-8">
          <div>
            <h2 class="texto-regular-bold text-center" style="color: #777777">Histórico de caso</h2>
          </div>

          <div v-if="isLoading === true">
            <div class="conteiner mt-5 mb-5">
              <div class="row mt-5">
                <div class="row mt-4">
                  <div class="col-2"></div>
                  <div class="col-8">
                    <div class="centered-container">
                      <div class="loading"></div>
                    </div>
                    <p class="text-font-regular" style="margin-top: 10px; text-align: center">
                      Carregando...
                    </p>
                  </div>
                  <div class="col-2"></div>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <div v-if="Report !== null">
              <div class="container-info mt-4">
                <h5 class="texto-regular-bold" style="color: #777777">Nº de protocolo: </h5>
                <h5 class="texto-regular-bold" style="color: #777777">{{ Report.id }}</h5>
              </div>
              <div class="container-info mt-4">
                <h5 class="texto-regular-bold" style="color: #777777">Paciente: </h5>
                <h5 class="texto-regular-bold" style="color: #777777">{{
                  Report.protocol_update_info.protocol_info.patient_info.name }}</h5>
              </div>
              <h5 class="texto-regular-bold mt-4" style="color: #777777">Revise as informações abaixo:</h5>


              <div class="container-historical mt-4">
                <div>
                  <div v-for="field in ReportFields" :key="field.id">
                    <div v-if="Report[field.name] != null">
                      <div v-if="field.name == 'sedated'">
                        <div v-if="Report[field.name] > 0">
                          <div class="mt-4">
                            <p class="texto-regular">{{ field.description }}<strong class="texto-regular-bold"
                                style="word-wrap: break-word; text-align: justify;">{{ formatRamsay(Report[field.name])
                                }}</strong>
                            </p>
                          </div>
                        </div>
                        <div v-else>
                          <div class="mt-4">
                            <p class="texto-regular">{{ field.description }}<strong class="texto-regular-bold"
                                style="word-wrap: break-word; text-align: justify;">Não</strong></p>
                          </div>
                        </div>
                      </div>
                      <div v-else-if="field.name == 'cid_subcategory'">
                        <div class="mt-4">
                          <p class="texto-regular">{{ field.description }} <strong class="texto-regular-bold"
                              style="word-wrap: break-word; text-align: justify;">{{ Report[field.name].description }}</strong></p>
                        </div>
                      </div>
                      <div v-else-if="field.name == 'transference'">
                        <div class="mt-4">
                          <p class="texto-regular">{{ field.description }} <strong class="texto-regular-bold"
                              style="word-wrap: break-word; text-align: justify;">{{ Report[field.name] === 1 ? "Sim" :
                                "Não"
                              }}</strong></p>
                        </div>
                      </div>
                      <div v-else>
                        <div class="mt-4">
                          <p class="texto-regular">{{ field.description }} <strong class="texto-regular-bold"
                              style="word-wrap: break-word; text-align: justify;">{{ Report[field.name] }}</strong></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div v-if="verifyOwnerReport()">
                <div v-if="findForStep4(Report.statuses) == false">
                  <div class="attentive-buttons">
                    <button class="btn-back-queue" @click="showModalLaudoComplete()">ASSINAR LAUDO</button>
                  </div>
                </div>
              </div>
              <div class="attentive-buttons">
                <button class="btn-review" @click="downloadLaudoHistoric()">DOWNLOAD LAUDO</button>
              </div>
            </div>
          </div>
          <div class="col-2"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ModalInfoBot from "../components/ModalInfoBot.vue";
import ModalLoadingSignatureHistoric from "../components/ModalLoadingSignature.vue"
import { mapGetters, mapActions } from "vuex";
export default {
  name: `Review`,
  components: {
    ModalInfoBot,
    ModalLoadingSignatureHistoric
  },
  computed: {
    ...mapGetters({
      Report: "StateReport",
      User: "StateUser",
      UrlReport: "StateUrlReport",
      ReportFields: "StateReportFields"
    }),
  },
  data() {
    return {
      isLoading: true,
      protocolInfo: Object,
      isModalInfoBotVisible: false,
      isModalLoadingSignature: false,
      typeSteps: "",
      reportId: null,
    };
  },
  async created() {
    this.reportValue = this.Report
    if (!this.isAuthenticated()) {
      this.$router.push("/login");
    }
    if (this.Report === null) {
      this.reportId = JSON.parse(localStorage.getItem('reportId'));
      if (this.reportId != null) {
        await this.GetReportById(this.reportId);
        localStorage.removeItem('reportId');
        this.isLoading = false;
      }
      else {        
        this.$router.push("/historical");
      }
    }
  },
  async mounted() {
    try {
      if (this.Report !== null) {
        await this.GetReportFields(this.Report.protocol_update_info.protocol_info.specialty_id);
        this.isLoading = false;
      }
    }
    catch {
      this.$toast.error('Ocorreu um erro, tente novamente.', {
        timeout: 3000,
        closeOnClick: true
      });
    }
  },
  methods: {
    ...mapActions(["GetReportFields"]),
    ...mapActions(["SetReport"]),
    ...mapGetters(["isAuthenticated"]),
    ...mapActions(["GetReport"]),
    ...mapActions(["GenerateReport"]),
    ...mapActions(["ResignReport"]),
    ...mapActions(["GetReportById"]),
    verifyOwnerReport() {
      if (this.User.specialist.id == this.Report.specialist_info.id) {
        return true;
      }
      return false;
    },
    backReport() {
      this.$router.push('/historical');
    },
    showModalInfoBot(report) {
      this.protocolInfo = report.protocol_update_info;
      this.isModalInfoBotVisible = true;
    },
    closeModalInfoBot() {
      this.isModalInfoBotVisible = false;
    },
    alterarLoading(valueLoading) {
      this.isLoading = valueLoading;
    },
    async showModalLaudoComplete() {
      this.ResignReport(this.Report)
      this.typeSteps = "historic"
      this.isModalLoadingSignature = true;
    },
    closeModalLoadingSignature() {
      this.isModalLoadingSignature = false;
    },
    async downloadLaudoHistoric() {
      try {
        await this.GenerateReport(this.Report)
        const pdfUrl = this.UrlReport;
        const link = document.createElement('a');
        link.href = pdfUrl;
        link.target = '_blank';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
      catch {
        this.$toast.error('Ocorreu um erro, tente novamente.', {
          timeout: 3000,
          closeOnClick: true
        });
      }
    },
    findForStep4(statuses) {
      const status = statuses.find((status) => status.step === 4);
      if (status) {
        if (status.status == "success") {
          return true;
        }
        return false;
      } else {
        return false;
      }
    },
    formatRamsay(number) {
      return "Ramsay " + number
    }
  },
};
</script>

<style>
.button-back {
  background-color: #ffff;
  border: none;
}

.floating-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 60px;
  height: 60px;
  background-color: #6200EE;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 9999;
}

.button-image {
  width: 20px;
  height: 20px;

}

.attentive-buttons {
  margin-top: 25px;
  display: flex;
  justify-content: center;
}

.texto-regular-bold {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 5px;
}

.separate {
  display: inline-block;
  align-self: stretch;
  width: 2px;
  min-height: 1em;
  opacity: 0.25;
}

.btn-transfer {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #6200EE;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border: none;
  border-radius: 20px;
  padding: 10px;
  width: 300px;
  cursor: pointer;
  margin: 0 auto;
}

.btn-back-queue {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #6200EE;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border: 1px solid #6200ee;
  border-radius: 20px;
  padding: 10px;
  width: 300px;
  cursor: pointer;
  margin: 0 auto;
}

.btn-review {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #6200EE;
  border: 1px solid #6200ee;
  border-radius: 20px;
  padding: 10px;
  width: 300px;
  cursor: pointer;
  margin: 0 auto;
}

.texto-regular {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
}

.container-info {
  display: flex;
}

.button-copy {
  padding: 0;
  border: none;
  background: none;
}

.text-font-copy {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #6200ee;
}

.separate {
  display: inline-block;
  align-self: stretch;
  width: 2px;
  min-height: 1em;
  opacity: 0.25;
}

.container-tittle {
  display: flex;
  align-items: center;
  justify-content: center;
}

.imagem-tittle {
  width: 35px;
  height: 35px;
}

.container-info {
  display: flex;
}

.texto-tittle {
  text-align: center;
  margin-top: 15px;
  margin-left: 10px;
  padding: 10px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #6200ee;
}

.texto-institution {
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  margin-top: 10px;
  color: #777777;
}

.texto-regular {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
}

.texto-regular-bold {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 5px;
}

.texto-hospital {
  text-align: center;
  margin-left: 10px;
  margin-top: 10px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #777777;
}

.btn-back {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #6200EE;
  border: 1px solid #6200ee;
  border-radius: 20px;
  padding: 10px;
  width: 100px;
  cursor: pointer;
  margin: 0 auto;
}

.attentive-buttons {
  margin-top: 25px;
  display: flex;
  justify-content: center;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 24px;
  color: #666666;
  cursor: pointer;
}

.loading {
  width: 50px;
  height: 50px;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.centered-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
