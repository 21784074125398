<template>
  <div v-if="visibleModalLoadingSignature" class="modal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div v-if="statusSigned === true">
          <div class="modal-image">
            <img src="../assets/status-success-laudo.png" alt="logo-loading" />
          </div>
          <h2 class="modal-title text-font-attention" style="margin-bottom: 15px;">Sucesso!</h2>
          <p class="modal-text text-font-regular">
            Seu laudo foi assinado com sucesso! Faça o download e envie o documento para o cliente. Se desejar baixar
            novamente, basta acessar o caso no histórico.
          </p>
          <div>
            <button class="btn-back-laudo attentive-buttons" @click="downloadLaudoModal">
              DOWNLOAD DO LAUDO
            </button>
            <button class="btn-back-queue-laudo attentive-buttons" @click="backProtocols">
              VOLTAR PARA HOME
            </button>
          </div>
        </div>
        <div v-else>
          <div class="modal-image">
            <img src="../assets/loading-signature.png" alt="logo-loading" />
          </div>
          <h2 class="modal-title text-font-attention" style="margin-bottom: 15px;">Aguarde enquanto geramos o laudo</h2>
          <div style="align-items: center;">
            <div v-for="(step, index) in steps" :key="index" class="step">
              <div class="status">
                <template v-if="step.status == 1">
                  <p class="text-font-ok">✔</p>
                </template>
                <template v-if="step.status == 2">
                  <img alt="avatar1" src="../assets/error-loading-signature.png" />
                </template>
                <template v-if="step.status == 0">
                  <div class="loading_modal" style="margin-top: 5px;"></div>
                </template>
              </div>
              <div class="text" style="margin-top: 5px;">{{ step.text }}</div>
            </div>
          </div>
          <div v-if="showRetryButton">
            <button class="btn-back-return attentive-buttons" @click="retryTentativeGenerate">
              TENTAR NOVAMENTE
            </button>
            <button class="btn-back-queue-laudo attentive-buttons" @click="backProtocols">
              VOLTAR PARA HOME
            </button>
            <p class="modal-text text-font-regular">Caso o problema persista, acione o suporte</p>
          </div>
          <div v-if="showDownloadButton">
            <button class="btn-back-return attentive-buttons" @click="retryTentativeResign">
              TENTAR NOVAMENTE
            </button>
            <p class="modal-text text-font-regular">Caso o problema persista, faça o
              <a class="link-button" @click="downloadLaudoModal">download aqui</a> do laudo sem assinatura
            </p>
            <button class="btn-back-queue-laudo attentive-buttons" @click="backProtocols">
              VOLTAR PARA HOME
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  props: {
    typeSteps: String,
    visibleModalLoadingSignature: Boolean,
  },
  computed: {
    ...mapGetters({
      Report: "StateReport",
      User: "StateUser",
      UrlReport: "StateUrlReport"
    }),
  },
  data() {
    return {
      steps: [
        { text: "Salvar arquivo.", status: 0 }, //0 - loading, 1 - success, 2 - error
        { text: "Assinatura iniciada.", status: 0 },
        { text: "Assinatura finalizada.", status: 0 },
        { text: "Arquivo assinado salvo.", status: 0 },
      ],
      stopVerify: false,
      modalVerify: null,
      statusSigned: false,
      showDownloadButton: false,
      showRetryButton: false,
    };
  },
  async mounted() {
    this.modalVerify = setInterval(() => {
      this.validSteps();
    }, 2000);
  },
  beforeUnmount() {
    clearInterval(this.modalVerify);
  },
  methods: {
    ...mapActions(["GetEnable"]),
    ...mapActions(["GetReport"]),
    ...mapActions(["UpdateReport"]),
    ...mapActions(["GenerateReport"]),
    ...mapActions(["ResignReport"]),
    ...mapActions(["GenerateUrlReport"]),
    closeModalLoadingSignature() {
      this.$emit("close");
    },
    backProtocols() {
      this.$router.push('/protocols');
    },
    async validSteps() {
      if (this.typeSteps == "historic") {
        this.processLoadingHistoric();
      }
      if (this.typeSteps == "review") {
        this.processLoadingReview();
      }
    },

    async downloadLaudoModal() {
      try {
        await this.GenerateReport(this.Report)
        const pdfUrl = this.UrlReport;
        const link = document.createElement('a');
        link.href = pdfUrl;
        link.target = '_blank';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
      catch {
        this.$toast.error('Ocorreu um erro, tente novamente.', {
          timeout: 3000,
          closeOnClick: true
        });
      }
    },

    async processLoadingHistoric() {
      if (this.Report.finished == 1 && this.stopVerify == false) {
        await this.GetReport(this.Report);
        this.showRetryButton = false;
        this.steps[0].status = 1;
        var assinaturaIniciada = this.Report.statuses.find(function (elemento) {
          return elemento.step === 2;
        });
        if (assinaturaIniciada) {
          if (assinaturaIniciada.status == "success") {
            this.steps[1].status = 1;
            var assinaturaFinalizada = this.Report.statuses.find(function (elemento) {
              return elemento.step === 3;
            });
            if (assinaturaFinalizada) {
              if (assinaturaFinalizada.status == "success") {
                this.steps[2].status = 1;
                var arquivoAssinado = this.Report.statuses.find(function (elemento) {
                  return elemento.step === 4;
                });
                if (arquivoAssinado) {
                  if (arquivoAssinado.status == "success") {
                    this.steps[3].status = 1;
                    this.showDownloadButton = false,
                      this.showRetryButton = false
                    clearInterval(this.modalVerify);
                    this.statusSigned = true
                  }
                  if (arquivoAssinado.status == "error") {
                    this.steps[3].text = arquivoAssinado.description;
                    this.steps[3].status = 2;
                    this.stopVerify = true
                    this.showDownloadButton = true
                    clearInterval(this.modalVerify);
                  }
                }
              }
              if (assinaturaFinalizada.status == "error") {
                this.steps[2].text = assinaturaFinalizada.description;
                this.steps[2].status = 2;
                this.steps[3].status = null;
                this.stopVerify = true
                this.showDownloadButton = true
                clearInterval(this.modalVerify);
              }
            }
          }
          if (assinaturaIniciada.status == "error") {
            this.steps[1].text = assinaturaIniciada.description;
            this.steps[1].status = 2;
            this.steps[2].status = null;
            this.steps[3].status = null;
            this.stopVerify = true
            clearInterval(this.modalVerify);
            this.showDownloadButton = true;
          }
        }
      }
    },

    async processLoadingReview() {
      if (this.Report.finished == 1 && this.stopVerify == false) {
        await this.GetReport(this.Report);
        if (this.Report.statuses.length === 0) {
          this.steps[0].status = 2;
          this.steps[1].status = null;
          this.steps[2].status = null;
          this.steps[3].status = null;
          this.stopVerify = true;
          clearInterval(this.modalVerify);
          this.showRetryButton = true;
        }
        else {
          var arquivoSalvo = this.Report.statuses.find(function (elemento) {
            return elemento.step === 1;
          });
          if (arquivoSalvo) {
            if (arquivoSalvo.status == "success") {
              this.showRetryButton = false;
              this.steps[0].status = 1;
              var assinaturaIniciada = this.Report.statuses.find(function (elemento) {
                return elemento.step === 2;
              });
              if (assinaturaIniciada) {
                if (assinaturaIniciada.status == "success") {
                  this.steps[1].status = 1;
                  var assinaturaFinalizada = this.Report.statuses.find(function (elemento) {
                    return elemento.step === 3;
                  });
                  if (assinaturaFinalizada) {
                    if (assinaturaFinalizada.status == "success") {
                      this.steps[2].status = 1;
                      var arquivoAssinado = this.Report.statuses.find(function (elemento) {
                        return elemento.step === 4;
                      });
                      if (arquivoAssinado) {
                        if (arquivoAssinado.status == "success") {
                          this.steps[3].status = 1;
                          this.showDownloadButton = false,
                            this.showRetryButton = false
                          clearInterval(this.modalVerify);
                          this.statusSigned = true
                        }
                        if (arquivoAssinado.status == "error") {
                          this.steps[3].text = arquivoAssinado.description;
                          this.steps[3].status = 2;
                          this.stopVerify = true
                          this.showDownloadButton = true
                          clearInterval(this.modalVerify);
                        }
                      }
                    }
                    if (assinaturaFinalizada.status == "error") {
                      this.steps[2].text = assinaturaFinalizada.description;
                      this.steps[2].status = 2;
                      this.steps[3].status = null;
                      this.stopVerify = true
                      this.showDownloadButton = true
                      clearInterval(this.modalVerify);
                    }
                  }
                }
                if (assinaturaIniciada.status == "error") {
                  this.steps[1].text = assinaturaIniciada.description;
                  this.steps[1].status = 2;
                  this.steps[2].status = null;
                  this.steps[3].status = null;
                  this.stopVerify = true
                  clearInterval(this.modalVerify);
                  this.showDownloadButton = true;
                }
              }
            }
            if (arquivoSalvo.status == "error") {
              this.steps[0].status = 2;
              this.steps[1].status = null;
              this.steps[2].status = null;
              this.steps[3].status = null;
              this.stopVerify = true
              clearInterval(this.modalVerify);
              this.showRetryButton = true;
            }
          }
        }
      }
    },

    resetFlow() {
      this.stopVerify = false
      this.statusSigned = false,
      this.showDownloadButton = false,
      this.showRetryButton = false
      this.steps[0].status = 0;
      this.steps[0].text = "Salvar arquivo.";
      this.steps[1].status = 0;
      this.steps[1].text = "Assinatura iniciada.";
      this.steps[2].status = 0;
      this.steps[2].text = "Assinatura finalizada.";
      this.steps[3].status = 0;
      this.steps[3].text = "Arquivo assinado salvo.";
    },

    retryTentativeResign() {
      this.resetFlow()
      this.ResignReport(this.Report)
      this.modalVerify = setInterval(() => {
        this.validSteps();
      }, 2000);
    },

    retryTentativeGenerate() {
      this.resetFlow()
      this.GenerateUrlReport(this.Report)
      this.modalVerify = setInterval(() => {
        this.validSteps();
      }, 2000);
    },
  }
}

</script>

<style>
.step {
  display: flex;
}

.status {
  margin-right: 10px;
  font-size: 24px;
}

.loading_modal {
  width: 24px;
  height: 24px;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.link-button {
  color: #6200ee;
  /* Cor inicial do texto */
  text-decoration: none;
  cursor: pointer;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.btn-back-return {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #ffff;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #6200ee;
  border: 1px solid #6200ee;
  border-radius: 20px;
  padding: 10px;
  width: 200px;
  cursor: pointer;
  margin-bottom: 10px;
}


.attentive-buttons {
  margin-top: 25px;
  display: flex;
  justify-content: center;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-dialog {
  background-color: #fff;
  width: 500px;
  max-width: 100%;
  border-radius: 20px;
}

.modal-content {
  background-color: #fff;
  border-radius: 10px;
  max-height: 90%;
  overflow: auto;
  padding: 30px;
  border: none;
  text-align: center;
}

.modal-image {
  margin-bottom: 20px;
}

.modal-image img {
  max-width: 80px;
  max-height: 80px;
}

.modal-title {
  font-size: 24px;
  margin-top: 0;
}

.modal-text {
  font-size: 18px;
  margin-bottom: 20px;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 24px;
  color: #666666;
  cursor: pointer;
}

.text-font-attention {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #6200ee;
}

.text-font-ok {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  color: green;
}


.text-font-regular {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  color: #777777;
}

.align-text {
  text-align: center;
}

.btn-back-queue-laudo {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #6200EE;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border: 1px solid #6200ee;
  border-radius: 20px;
  padding: 10px;
  width: 200px;
  cursor: pointer;
  margin: 0 auto;
}
</style>
