import Login from './views/Login.vue'
import Protocols from './views/Protocols.vue'
import Report from './views/Report.vue'
import Review from './views/Review.vue'
import Historical from './views/Historical.vue'
import HistoricalReview from './views/HistoricalReview.vue'

import { createRouter, createWebHistory} from "vue-router";

const routes = [
    {
        path: "/",
        name: "Home",
        component: Protocols,
    },
    {
        name: 'Login',
        component:Login,
        path:'/login',
        meta: {
            hideNavbar: true,
        }
    },
    {
        name: 'Protocols',
        component:Protocols,
        path:'/protocols'
    },
    {
        name: 'Report',
        component:Report,
        path:'/report',
    },
    {
        name: 'Review',
        component:Review,
        path:'/review',
    },
    {
        name: 'Historical',
        component:Historical,
        path:'/historical',
    },
    {
        name: 'HistoricalReview',
        component:HistoricalReview,
        path:'/historicalreview',
    }        
];

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router