export default {
    setUser(state, user) {
        state.user = user
        localStorage.setItem('user', JSON.stringify(user));
    },

    setProtocols(state, protocols) {
        state.protocols = protocols;
    },

    setReports(state, reports) {
        state.reports = reports;
    },

    setReportsUnsigned(state, reports) {
        state.reportsUnsigned = reports;
    },

    setExams(state, exams) {
        state.exams = exams;
    },

    setDiagnosticHypothesis(state, diagnostics) {
        state.diagnosticHypothesis = diagnostics;
    },

    setOutcomes(state, outcomes) {
        state.outcomes = outcomes;
    },

    logout(state, user) {
        state.user = user;
        localStorage.removeItem('user');
    },

    setProtocol(state, protocol) {
        state.protocol = protocol;
    },

    setReport(state, report) {
        state.report = report;
    },

    setSpecialistsActives(state, specialistsActives) {
        state.specialistsActives = specialistsActives;
    },

    setEnable(state, enable) {
        state.enable = enable;
    },    

    setTokenMemed(state, tokenMemed) {
        state.tokenMemed = tokenMemed;
    },   

    setUrlReport(state, urlReport) {
        state.urlReport = urlReport;
    },

    setUrlFile(state, urlFile) {
        state.urlFile = urlFile;
    },

    setModalSignatureRequired(state, isOpen) {
        state.isModalSignatureRequiredOpen = isOpen;
    },

    setReportFields(state, reportFields) {
        state.reportFields = reportFields;     
    }
}