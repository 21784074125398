import "bootstrap/dist/css/bootstrap.css"
import {createApp} from 'vue'
import App from './App.vue'
import router from "./routers"
import axios from "axios"
import store from "./store"

import { library } from "@fortawesome/fontawesome-svg-core";
import { faArrowRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import 'vue-toastification/dist/index.css'; 
import { useToast } from 'vue-toastification';

import BootstrapVue3 from 'bootstrap-vue-3'

import Select2 from 'vue3-select2-component';

axios.defaults.baseURL = process.env.VUE_APP_API_URL
axios.defaults.withCredentials = false;

library.add(faArrowRightFromBracket)
library.add(fas)

const toast = useToast({
    position: 'top-right', 
    timeout: 3000, 
    closeOnClick: true 
  });  

const app = createApp(App)
            .component('Select2', Select2)
            .component("font-awesome-icon", FontAwesomeIcon)
            .use(router)
            .use(store)
            .use(BootstrapVue3)

app.config.globalProperties.$toast = toast; 

app.mount('#app');

