export default {
    isAuthenticated(state) {
        const user = localStorage.getItem('user');
        if (user !== null) {
            state.user = JSON.parse(user);
            return state.user;
        }
        return null;
    },
    StateProtocols: (state) => state.protocols,
    StateReports: (state) => state.reports,    
    StateReportsUnsigned: (state) => state.reportsUnsigned,   
    StateProtocol: (state) => state.protocol,
    StateExams: (state) => state.exams,
    StateDiagnosticHypothesis: (state) => state.diagnosticHypothesis,
    StateOutcomes: (state) => state.outcomes,
    StateUser: (state) => state.user,     
    StateTokenMemed: (state) => state.tokenMemed,     
    StateEnable: (state) => state.enable,
    StateReport: (state) => state.report,
    StateSpecialistsActives: (state) => state.specialistsActives,
    StateUrlReport: (state) => state.urlReport,
    StateUrlFile: (state) => state.urlFile,
    isModalSignatureRequiredOpen: state => state.isModalSignatureRequiredOpen,
    StateReportFields: (state) => state.reportFields,
}