<template>
  <div v-if="visibleModalPendency" class="modal">
    <div class="modal-dialog" id="modalPhoto">
      <div class="modal-content">
        <button class="close-button" @click="closeModalPendency" style="color: #6200ee;">X</button>        
          <div class="modal-image">
            <img src="../assets/pendency-signed.png" alt="ic_cam" />
          </div>
          <h2 class="modal-title text-font-attention">Assinatura de laudo pendente</h2>
          <p style="color: #777;">
            Identificamos que um laudo não foi assinado ao término da jornada. <b>Por favor, resolva essa pendência imediatamente.</b> Clique no botão abaixo para verificar e concluir a assinatura.
          </p>
          <div class="modal-buttons" style="margin-top: 15px;">
            <button class="btn-upload-save" :disabled="isButtonAtenderDisabled" @click="openHistory(reportUnsigned)">
              RESOLVER PENDÊNCIA
            </button>
          </div>
        
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import 'vue-advanced-cropper/dist/style.css';
export default {  
  props: {
    reportUnsigned: Object,
    visibleModalPendency: Boolean,
  },  
  data() {
    return {   
      isButtonResolve: false,   
    };
  },
  methods: {
    ...mapActions(["GetReportById"]),
    closeModalPendency() {
      this.$emit("close");
    },
    async openHistory(reportUnsigned) {
      try {
        this.isButtonResolve = true;
        await this.GetReportById(reportUnsigned.id);
        this.$router.push({ name: 'HistoricalReview' });       
      }
      catch (erro) {        
        this.$toast.error('Ocorreu um erro, tente novamente.', {
          timeout: 3000,
          closeOnClick: true
        });        
      }
    },
  },
};
</script>

<style>
.btn-upload-save {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #6200ee;
  border: 1px solid #6200ee;
  border-radius: 20px;
  padding: 10px;
  width: 300px;
  cursor: pointer;
  margin: 0 auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-dialog {
  background-color: #fff;
  width: 600px;
  border-radius: 20px;
}

.modal-content {
  background-color: #fff;
  border-radius: 10px;
  max-height: 90%;
  overflow: auto;
  padding: 30px;
  border: none;
  text-align: center;
}

.modal-image {
  margin-bottom: 20px;
}

.modal-image img {
  max-width: 80px;
  max-height: 80px;
}

.modal-title {
  font-size: 24px;
  margin-top: 0;
}

.modal-text {
  font-size: 18px;
  margin-bottom: 20px;
}

.modal-buttons {
  display: flex;
  justify-content: center;
}

.modal-button {
  background-color: #3498db;
  color: #fff;
  border: none;
  border-radius: 10px;
  padding: 10px 20px;
  margin-top: 20px;
  font-size: 18px;
  cursor: pointer;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 24px;
  color: #666666;
  cursor: pointer;
}

.text-font-attention {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #6200ee;
}

.text-font-regular {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  color: #777777;
}

.align-text {
  text-align: center;
}
</style>
