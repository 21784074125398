<template>
  <div class="floating-button-bot" @click="showModalInfoBot(Report)">
    <img src="../assets/bot-svgrepo-com-white.png" alt="Imagem" class="button-image">
  </div>
  <div class="chat-button" @click="toggleChat()">
    <img src="../assets/chat.png" alt="Imagem">
  </div>
  <ModalInfoBot :visibleModalInfoBot="isModalInfoBotVisible" @close="closeModalInfoBot"
                :protocolInfoPopup="protocolInfo" :enableButton="false"></ModalInfoBot>
  <ModalReturnCase :visibleModalReturn="isModalReturnVisible" @close="closeModalReturn"></ModalReturnCase>
  <ModalTransferSpecialist :visibleModalTransfer="isModalTransferSpecialistVisible"
                           @close="closeModalTransferSpecialist">
  </ModalTransferSpecialist>
  <div class="col-12">
    <div class="mt-5 mb-5">
      <div class="row">
        <div class="col-2">
          <button class="button-back mx-3" @click="backProtocols()">
            <img src="../assets/back-button.png" alt="Button Image">
          </button>
        </div>
        <div class="col-8">
          <div v-if="Report !== null">
            <div>
              <h2 class="texto-regular-bold text-center" style="color: #777777">Formulário de atendimento
                especializado</h2>
            </div>

            <div v-if="isLoading === true">
              <div class="conteiner mt-5 mb-5">
                <div class="row mt-5">
                  <div class="row mt-4">
                    <div class="col-2"></div>
                    <div class="col-8">
                      <div class="centered-container">
                        <div class="loading"></div>
                      </div>
                      <p class="text-font-regular" style="margin-top: 10px; text-align: center">
                        Carregando...
                      </p>
                    </div>
                    <div class="col-2"></div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <div class="container-info mt-4">
                <h4 class="texto-regular-bold" style="color: #777777">Paciente: </h4>
                <h4 class="texto-regular-bold" style="color: #777777">{{ formatName(pacient) }}</h4>
              </div>
              <div v-if="Report.protocol_update_info.imaging_exam !== null">
                <div class="container-info mt-4">
                  <h5 class="texto-regular-bold" style="color: #777777">Exame de imagem realizado:
                  </h5>
                  <h5 class="texto-regular-bold" style="color: #777777">{{
                      Report.protocol_update_info.imaging_exam
                    }}</h5>
                </div>
              </div>
              <div class="container-info mt-4">
                <h6 class="texto-regular-bold" style="margin-top: 4px; color: #777777">Último
                  Salvamento:
                </h6>
                <h6 class="texto-regular-bold" style="margin-top: 4px; color: #777777">{{
                    Report.updated_at
                  }}</h6>

                <div v-if="loadingButtonSave">
                  <button class="custom-button-save" @click="saveData()">SALVAR</button>
                </div>
                <div v-else>
                  <button class="custom-button-save">Carregando...</button>
                </div>
              </div>

              <h5 class="texto-regular-bold mt-4" style="color: #777777">Preencha os campos abaixo:</h5>
              <form>
                <div>
                  <dynamic-report :reportFields="this.ReportFields" :reportInfo="this.Report"></dynamic-report>
                </div>
              </form>
              <div v-if="loadingButton">
                <div class="attentive-buttons">
                  <button class="btn-review" @click.prevent="openReview()">REVISAR E ENVIAR</button>
                </div>
                <div class="attentive-buttons">
                  <button class="btn-back-queue" @click="showModalReturn()">DEVOLVER CASO PARA
                    FILA
                  </button>
                </div>
              </div>
              <div v-else>
                <p class="text-font-regular"
                   style="color: #777777; margin-top: 10px; text-align: center">
                  Carregando...
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-2"></div>
      </div>
    </div>
  </div>
</template>

<script>
import ModalInfoBot from "../components/ModalInfoBot.vue";
import ModalTransferSpecialist from "../components/ModalTransferSpecialist.vue";
import ModalReturnCase from "../components/ModalReturnCase.vue";
import DynamicReport from "../components/DynamicReport.vue";
import {mapGetters, mapActions} from "vuex";

export default {
  name: `AttendanceForm`,
  props: ['protocol'],
  components: {
    DynamicReport,
    ModalInfoBot,
    ModalReturnCase,
    ModalTransferSpecialist
  },
  computed: {
    ...mapGetters({
      User: "StateUser",
      Report: "StateReport",
      ReportFields: "StateReportFields"
    }),
  },
  async mounted() {
    try {
      if (this.Report !== null) {
        try{
          await this.callClient();
        }catch (err){
          console.log("Erro ao iniciar o chat.")
        }
        await this.GetReport(this.Report);
        await this.GetReportFields(this.Report.protocol_update_info.protocol_info.specialty_id);        
        this.pacient = this.Report != null ? this.Report.protocol_update_info !== undefined && this.Report.protocol_update_info !== null ? this.Report.protocol_update_info.protocol_info.patient_info.name : '' : '';
        for (const field in this.fieldMappings) {
          const reportField = this.fieldMappings[field];
          const value = this.Report != null ? this.Report[reportField] !== undefined && this.Report[reportField] !== null ? this.Report[reportField] : null : null
          this.setFieldValue(field, value);
        }
        //Específico:
        this.setFieldValue("video_realized", this.Report != null ? this.Report.video_findings !== undefined && this.Report.video_findings !== null ? 1 : 0 : 0);
        this.applyRules();
        this.isLoading = false;
      }
    } catch(err) {
      console.log(err)
      this.$toast.error('Ocorreu um erro, tente novamente.', {
        timeout: 3000,
        closeOnClick: true
      });
    }
  },
  data() {
    return {
      loadingButton: true,
      loadingButtonSave: true,
      fieldMappings: {
        "clinical_history": "clinical_history",
        "specialist_findings": "specialist_findings",
        "diagnostic_hypothesis": "diagnostic_hypothesis_id",
        "diagnostic_hypothesis_another": "diagnostic_hypothesis_another",
        "diagnostic_complement": "diagnostic_complement",
        "sedated": "sedated",
        "glasgow": "glasgow",
        "transference": "transference",
        "outcome": "outcome_type_id",
        "conduct": "conduct",
        "specialized_prescription": "specialized_prescription",
        "video_findings": "video_findings",
        "daily_goal": "daily_goal",
        "daily_evolution": "daily_evolution",
        "glycated_hemoglobin": "glycated_hemoglobin",
        "leukogram": "leukogram",
        "platelet_count": "platelet_count",
        "crp": "crp",
        "urea": "urea",
        "creatinine": "creatinine",
        "blood_gas_analysis": "blood_gas_analysis",
        "sodium": "sodium",
        "potassium": "potassium",
        "others": "others",
        "cid_subcategory": "cid_subcategory_id",
        "protocol_avc": "protocol_avc",
        "thrombolysis": "thrombolysis"
      },
      reportFields: [],
      isLoading: true,
      isModalInfoBotVisible: false,
      isModalReturnVisible: false,
      isModalTransferSpecialistVisible: false,
      protocolInfo: Object,
      pacient: null,
    };
  },
  async created() {
    if (!this.isAuthenticated()) {
      this.$router.push("/login");
    }
    if (this.Report === null) {
      this.$router.push("/");
    }
  },
  methods: {
    ...mapActions(["GetReportFields"]),
    ...mapActions(["GetEnable"]),
    ...mapActions(["GetReport"]),
    ...mapActions(["SetReport"]),
    ...mapActions(["UpdateReport"]),
    ...mapGetters(["isAuthenticated"]),
    ...mapActions(['openModalSignatureRequired']),
    async saveData() {
      this.loadingButtonSave = false
      try {
        await this.checkInputChange()
        await this.GetReport(this.Report);
        this.loadingButtonSave = true;
      } catch {
        this.$toast.error('Ocorreu um erro, tente novamente.', {
          timeout: 3000,
          closeOnClick: true
        });
        this.loadingButtonSave = true;
      }
    },
    applyRules() {
      const filteredArray = this.ReportFields.filter((item) => {
        return item.rules.length > 0;
      });
      filteredArray.forEach((field) => {
        field.rules.forEach((rule) => {
          const targetField = this.ReportFields.find(
              (item) => item.name === rule.parameters[2]
          );
          if (targetField && rule.parameters[0] === "field_equals") {
            if (rule.name == "reveals_to") {
              targetField.visible = rule.parameters[1] == this.recoverFieldValue(field.name);
            } else if (rule.name == "forces_to") {
              targetField.required = rule.parameters[1] == this.recoverFieldValue(field.name);
            }
          }
        });
      });
    },
    async checkInputChange() {
      const info = {};
      for (const field in this.fieldMappings) {
        const reportField = this.fieldMappings[field];
        const recoverFieldValue = this.recoverFieldValue(field) !== undefined && this.recoverFieldValue(field) !== null ? this.recoverFieldValue(field) : null;
        const reportValue = this.Report != null ? (this.Report[reportField] !== undefined && this.Report[reportField] !== null ? this.Report[reportField] : null) : null;
        if (recoverFieldValue !== reportValue) {
          info[reportField] = recoverFieldValue;
        }
      }
      const reportUpdate = {
        id: this.Report.id,
        info: info
      }
      if (Object.keys(reportUpdate.info).length > 0) {
        await this.UpdateReport(reportUpdate);
        this.$toast.success('Dados salvos com sucesso', {
          timeout: 3000,
          closeOnClick: true
        });
      } else {
        this.$toast.warning('Dados sem alterações, não foi necessário salvar', {
          timeout: 3000,
          closeOnClick: true
        });
      }
    },
    async saveChanges() {
      const info = {};
      for (const field in this.fieldMappings) {
        const reportField = this.fieldMappings[field];
        info[reportField] = this.recoverFieldValue(field);
      }
      const reportUpdate = {
        id: this.Report.id,
        info: info
      }
      await this.UpdateReport(reportUpdate);
    },
    recoverFieldValue(fieldName) {
      const filteredArray = this.ReportFields.filter((item) => {
        return item.name === fieldName;
      });
      if (filteredArray.length > 0) {
        return filteredArray[0].value;
      }
    },
    setFieldValue(fieldName, valueField) {
      const filteredArray = this.ReportFields.filter((item) => {
        return item.name === fieldName;
      });
      if (filteredArray.length > 0) {
        return filteredArray[0].value = valueField;
      }
    },
    formatName(name) {
      if (name !== '' && name !== null) {
        let words = name.toLowerCase().split(" ");
        for (let i = 0; i < words.length; i++) {
          let word = words[i];
          words[i] = word.charAt(0).toUpperCase() + word.slice(1);
        }
        return words.join(" ");
      } else {
        return '';
      }
    },
    closeModalInfoBot() {
      this.isModalInfoBotVisible = false;
    },
    showModalInfoBot(report) {
      this.protocolInfo = report.protocol_update_info;
      this.isModalInfoBotVisible = true;
    },
    toggleChat() {
      try{
        window.Hyperflow.toggle()
      }
      catch (error){
        console.log("Erro ao abrir o chat")
      }
    },
    showModalReturn() {
      this.isModalReturnVisible = true;
    },
    closeModalReturn() {
      this.isModalReturnVisible = false;
    },
    showModalTransferSpecialist() {
      this.isModalTransferSpecialistVisible = true;
    },
    closeModalTransferSpecialist() {
      this.isModalTransferSpecialistVisible = false;
    },
    async backProtocols() {
      try {
        await this.saveChanges();
        this.$router.push('/protocols');
      } catch {
        this.$toast.error('Ocorreu um erro, tente novamente.', {
          timeout: 3000,
          closeOnClick: true
        });
      }
    },
    validFields() {
      var fields = [];
      this.ReportFields.forEach(item => {
        if (item.required && (item.value === '' || item.value === null || item.value === undefined)) {
          fields.push(item.description.replace(/[:?]/g, ''));
        }
      });
      return fields;
    },

    async openReview() {
      try {
        this.loadingButton = false;
        await this.GetEnable();
        const dateActual = new Date();
        const resultValidacao = this.validFields();
        if (this.User.user_cess_access_info != null) {
          const dateExpired = new Date(this.User.user_cess_access_info.expired_at);
          if (dateActual > dateExpired) {
            this.openModalSignatureRequired();
          } else {
            await this.handleValidFields(resultValidacao);
          }
        } else {
          await this.handleValidFields(resultValidacao);
        }
      } catch (error) {
        this.handleError();
      } finally {
        this.loadingButton = true;
      }
    },

    async handleValidFields(resultValidacao) {
      if (resultValidacao.length === 0) {
        try {
          await this.saveChanges();
          await this.SetReport(this.Report);
          this.$router.push({name: 'Review'});
        } catch (error) {
          this.handleError();
        }
      } else {
        this.showValidationError(resultValidacao);
      }
    },

    showValidationError(resultValidacao) {
      this.$toast.error('Existem campos não preenchidos:\n' + resultValidacao.join('\n'), {
        timeout: 3000,
        closeOnClick: true
      });
    },
    handleError() {
      this.$toast.error('Ocorreu um erro, tente novamente.', {
        timeout: 3000,
        closeOnClick: true
      });
    },
    async callClient() {
      const number = this.Report.protocol_update_info.requester_phone;
      window.Hyperflow.initFlow('JQlzK59lV', {
        "clientWhatsApp": number,
        "sessionName": this.User.name,
        "receiverName": this.Report.protocol_update_info.nurse_info ? this.Report.protocol_update_info.nurse_info.name: this.Report.protocol_update_info.responsible_info.name
      })
      window.Hyperflow.open()
    }
  }
}
;
</script>

<style>
.custom-button-save {
  background: none;
  border: none;
  color: #6200ee;
  cursor: pointer;
  font-size: 16px;
  margin-left: 15px;
}
#hyper_toggle{
  display: none;
}
.aling-count {
  float: right;
  margin-top: 5px;
  margin-right: 5px;
}

.text-form-pep {
  border-radius: 10px;
}

.button-back {
  background-color: #ffff;
  border: none;
}

.floating-button-bot {
  position: fixed;
  bottom: 100px;
  right: 20px;
  width: 60px;
  height: 60px;
  background-color: #6200EE;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 9998;
}
.chat-button {
  position: fixed;
  bottom: 20px;
  right: 10px;
  width: 80px;
  height: 80px;
  display: flex;
  cursor: pointer;
  z-index: 9999;
}

.button-image {
  width: 20px;
  height: 20px;

}

.attentive-buttons {
  margin-top: 25px;
  display: flex;
  justify-content: center;
}

.texto-regular-bold {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 5px;
}

.separate {
  display: inline-block;
  align-self: stretch;
  width: 2px;
  min-height: 1em;
  opacity: 0.25;
}

.btn-transfer {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #6200EE;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border: none;
  border-radius: 20px;
  padding: 10px;
  width: 300px;
  cursor: pointer;
  margin: 0 auto;
}

.btn-back-queue {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #6200EE;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border: 1px solid #6200ee;
  border-radius: 20px;
  padding: 10px;
  width: 300px;
  cursor: pointer;
  margin: 0 auto;
}

.btn-review {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #6200EE;
  border: 1px solid #6200ee;
  border-radius: 20px;
  padding: 10px;
  width: 300px;
  cursor: pointer;
  margin: 0 auto;
}

.texto-regular {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
}

.container-info {
  display: flex;
}

.loading {
  width: 50px;
  height: 50px;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.centered-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>