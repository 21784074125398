<template>
  <div class="floating-button" @click="showModalInfoBot(Report)">
    <img src="../assets/bot-svgrepo-com-white.png" alt="Imagem" class="button-image">
  </div>
  <ModalInfoBot :visibleModalInfoBot="isModalInfoBotVisible" @close="closeModalInfoBot" :protocolInfoPopup="protocolInfo"
    :enableButton="false"> </ModalInfoBot>
  <ModalLoadingSignature :visibleModalLoadingSignature="isModalLoadingSignature" :typeSteps="typeSteps"
    @close="closeModalLoadingSignature"></ModalLoadingSignature>
  <div class="col-12">
    <div class="mt-5 mb-5">
      <div class="row">
        <div class="col-2">
          <button class="button-back mx-3" v-if="mostrarEditar" @click="backReport()">
            <img src="../assets/back-button.png" alt="Button Image">
          </button>
        </div>
        <div class="col-8">
          <div>
            <h2 class="texto-regular-bold text-center" style="color: #777777">Tela de revisão</h2>
          </div>

          <div v-if="isLoading === true">
            <div class="conteiner mt-5 mb-5">
              <div class="row mt-5">
                <div class="row mt-4">
                  <div class="col-2"></div>
                  <div class="col-8">
                    <div class="centered-container">
                      <div class="loading"></div>
                    </div>
                    <p class="text-font-regular" style="margin-top: 10px; text-align: center">
                      Carregando...
                    </p>
                  </div>
                  <div class="col-2"></div>
                </div>
              </div>
            </div>
          </div>
          <div v-else>

            <div class="container-info mt-4">
              <h5 class="texto-regular-bold" style="color: #777777">Paciente: </h5>
              <h5 class="texto-regular-bold" style="color: #777777">{{
                formatName(Report.protocol_update_info.protocol_info.patient_info.name) }}</h5>
            </div>

            <h5 class="texto-regular-bold mt-4" style="color: #777777">Revise as informações abaixo:</h5>

            <div class="container-historical mt-4">
              <div>
                <div v-for="field in ReportFields" :key="field.id">
                  <div v-if="Report[field.name] != null">
                    <div v-if="field.name == 'sedated'">
                      <div v-if="Report[field.name] > 0">
                        <div class="mt-4">
                          <p class="texto-regular">{{ field.description }}<strong class="texto-regular-bold"
                              style="word-wrap: break-word; text-align: justify;">{{ formatRamsay(Report[field.name])
                              }}</strong>
                          </p>
                        </div>
                      </div>
                      <div v-else>
                        <div class="mt-4">
                          <p class="texto-regular">{{ field.description }}<strong class="texto-regular-bold"
                              style="word-wrap: break-word; text-align: justify;">Não</strong></p>
                        </div>
                      </div>
                    </div>
                    <div v-else-if="field.name == 'transference'">
                      <div class="mt-4">
                        <p class="texto-regular">{{ field.description }} <strong class="texto-regular-bold"
                            style="word-wrap: break-word; text-align: justify;">{{ Report[field.name] === 1 ? "Sim" :
                              "Não"
                            }}</strong></p>
                      </div>
                    </div>
                    <div v-else-if="field.name == 'protocol_avc'">
                      <div class="mt-4">
                        <p class="texto-regular">{{ field.description }} <strong class="texto-regular-bold"
                            style="word-wrap: break-word; text-align: justify;">{{ Report[field.name] === 1 ? "Sim" :
                              "Não"
                            }}</strong></p>
                      </div>
                    </div>
                    <div v-else-if="field.name == 'thrombolysis'">
                      <div class="mt-4">
                        <p class="texto-regular">{{ field.description }} <strong class="texto-regular-bold"
                            style="word-wrap: break-word; text-align: justify;">{{ Report[field.name] === 1 ? "Sim" :
                              "Não"
                            }}</strong></p>
                      </div>
                    </div>
                    <div v-else-if="field.name == 'cid_subcategory'">
                      <div class="mt-4">
                        <p class="texto-regular">{{ field.description }} <strong class="texto-regular-bold"
                            style="word-wrap: break-word; text-align: justify;">{{ Report[field.name].description }}</strong></p>
                      </div>
                    </div>
                    <div v-else>
                      <div class="mt-4">
                        <p class="texto-regular">{{ field.description }} <strong class="texto-regular-bold"
                            style="word-wrap: break-word; text-align: justify;">{{ Report[field.name] }}</strong></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="attentive-buttons">
              <button class="btn-review" @click="showModalLaudoComplete()">CONCLUIR ATENDIMENTO</button>
            </div>
            <div class="attentive-buttons" v-if="mostrarEditar">
              <button class="btn-back-queue" @click="backReport()">EDITAR</button>
            </div>
          </div>
        </div>
        <div class="col-2"></div>
      </div>
    </div>
  </div>
</template>

<script>
import ModalInfoBot from "../components/ModalInfoBot.vue";
import ModalLoadingSignature from "../components/ModalLoadingSignature.vue"
import { mapGetters, mapActions } from "vuex";
export default {
  name: `Review`,
  components: {
    ModalInfoBot,
    ModalLoadingSignature
  },
  computed: {
    ...mapGetters({
      Report: "StateReport",
      User: "StateUser",
      ReportFields: "StateReportFields"
    }),
  },
  data() {
    return {
      isLoading: true,
      protocolInfo: Object,
      mostrarEditar: true,
      isModalInfoBotVisible: false,
      isModalLoadingSignature: false,
      diagnosticHypothesisVisible: false,
      typeSteps: ""
    };
  },
  async created() {
    if (!this.isAuthenticated()) {
      this.$router.push("/login");
    }
    else {
      if (this.Report === null) {
        this.$router.push("/");
      }
      else {
        try {
          if (this.Report != null) {
            await this.GetReport(this.Report);
            this.isLoading = false;
          }
        }
        catch {
          this.$toast.error('Ocorreu um erro, tente novamente.', {
            timeout: 3000,
            closeOnClick: true
          });
        }
      }
    }
  },
  async mounted() {    
    try {
      if (this.Report !== null) {
        await this.GetReportFields(this.Report.protocol_update_info.protocol_info.specialty_id);
      }
    }
    catch {
      this.$toast.error('Ocorreu um erro, tente novamente.', {
        timeout: 3000,
        closeOnClick: true
      });
    }
  },
  methods: {
    ...mapActions(["GetReportFields"]),
    ...mapGetters(["isAuthenticated"]),
    ...mapActions(["GetReport"]),
    ...mapActions(["UpdateReport"]),
    backReport() {
      this.$router.push('/report');
    },
    async showModalLaudoComplete() {
      this.saveChanges();
      this.typeSteps = "review"
      this.isModalLoadingSignature = true;
      this.mostrarEditar = false;
    },
    closeModalLoadingSignature() {
      this.isModalLoadingSignature = false;
    },
    saveChanges() {
      const reportUpdate = {
        id: this.Report.id,
        info: {
          ...{ finished: 1 },
        }
      }
      this.UpdateReport(reportUpdate);
    },
    showModalInfoBot(report) {
      this.protocolInfo = report.protocol_update_info;
      this.isModalInfoBotVisible = true;
    },
    closeModalInfoBot() {
      this.isModalInfoBotVisible = false;
    },
    formatName(name) {
      let words = name.toLowerCase().split(" ");
      for (let i = 0; i < words.length; i++) {
        let word = words[i];
        words[i] = word.charAt(0).toUpperCase() + word.slice(1);
      }
      return words.join(" ");
    },
    formatRamsay(number) {
      return "Ramsay " + number
    }
  },
};
</script>

<style>
.button-back {
  background-color: #ffff;
  border: none;
}

.floating-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 60px;
  height: 60px;
  background-color: #6200EE;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 9999;
}

.button-image {
  width: 20px;
  height: 20px;

}

.attentive-buttons {
  margin-top: 25px;
  display: flex;
  justify-content: center;
}

.texto-regular-bold {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 5px;
}

.separate {
  display: inline-block;
  align-self: stretch;
  width: 2px;
  min-height: 1em;
  opacity: 0.25;
}

.btn-transfer {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #6200EE;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border: none;
  border-radius: 20px;
  padding: 10px;
  width: 300px;
  cursor: pointer;
  margin: 0 auto;
}

.btn-back-queue {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #6200EE;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border: 1px solid #6200ee;
  border-radius: 20px;
  padding: 10px;
  width: 300px;
  cursor: pointer;
  margin: 0 auto;
}

.btn-review {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #6200EE;
  border: 1px solid #6200ee;
  border-radius: 20px;
  padding: 10px;
  width: 300px;
  cursor: pointer;
  margin: 0 auto;
}

.texto-regular {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
}

.container-info {
  display: flex;
}

.button-copy {
  padding: 0;
  border: none;
  background: none;
}

.text-font-copy {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #6200ee;
}

.separate {
  display: inline-block;
  align-self: stretch;
  width: 2px;
  min-height: 1em;
  opacity: 0.25;
}

.container-tittle {
  display: flex;
  align-items: center;
  justify-content: center;
}

.container-historical {
  background-color: #FFF;
  margin-top: 15px;
}

.imagem-tittle {
  width: 35px;
  height: 35px;
}

.container-info {
  display: flex;
}

.texto-tittle {
  text-align: center;
  margin-top: 15px;
  margin-left: 10px;
  padding: 10px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #6200ee;
}

.texto-institution {
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  margin-top: 10px;
  color: #777777;
}

.texto-regular {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
}

.texto-regular-bold {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 5px;
}

.texto-hospital {
  text-align: center;
  margin-left: 10px;
  margin-top: 10px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #777777;
}

.btn-back {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #6200EE;
  border: 1px solid #6200ee;
  border-radius: 20px;
  padding: 10px;
  width: 100px;
  cursor: pointer;
  margin: 0 auto;
}

.attentive-buttons {
  margin-top: 25px;
  display: flex;
  justify-content: center;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 24px;
  color: #666666;
  cursor: pointer;
}

.loading {
  width: 50px;
  height: 50px;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.centered-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>