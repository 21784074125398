<template>
    <div>
        <div class="container col-lg-6">
            <div class="col-12 mt-2 text-center">
                <img src="../assets/logo-alt.png" alt="Doc4Doc" class="col-6 mb-5"/>
                <div class="offset-1 m-4">
                    <p class="text-center text h5 text-black-50">
                        Faça login para acessar sua conta
                    </p>
                </div>
                <div class="col m-4 m">
                    <input type="text" class="form-control" placeholder="Celular com DDD" v-model="form.phone"
                           name="phone"
                           :class="{ 'is-invalid': form.error }">
                    <div class="invalid-feedback">
                        {{ form.error ? form.error.message : '' }}
                    </div>
                </div>
                <div class="col m-4">
                    <input type="password" class="form-control" placeholder="Senha" v-model="form.password"
                           name="password"
                           :class="{ 'is-invalid': form.error }">
                </div>
                <div class="d-grid">
                    <a class="m-4 btn btn-primary" @click="!form.loading && login()">
                        <span v-show="!form.loading">ENTRAR</span>
                        <div v-show="form.loading" class="spinner-border spinner-border-sm">
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import store from "@/store";

export default {
    name: `Login`,
    data() {
        return {
            form: store.state.form,
            user: store.state.user
        }
    },
    methods: {
        ...mapActions(["LogIn"]),
        ...mapGetters["isAuthenticated"],
        async login() {
            this.form.loading = true;
            const User = new FormData();
            User.append("phone", this.form.phone);
            User.append("password", this.form.password);
            try {
                await this.LogIn(User)
                this.$router.push('/protocols')
                this.form.loading = false
            } catch (error) {
                this.form.loading = false;
                this.$toast.error('Ocorreu um erro, tente novamente.', {
                timeout: 3000,
                closeOnClick: true
                });
            }
        }
    }
}
</script>

<style scoped>

</style>