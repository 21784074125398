<template>
  <div class="container">
    <ModalChangePhoto :visibleModalChangePhoto="isModalVisible" @acao-concluida="acaoConcluidaHandler"
      @close="closeModalChangePhoto">
    </ModalChangePhoto>
    <ModalPendency :visibleModalPendency="isModalPendencyVisible" @close="closeModalPendency"
      :reportUnsigned="reportUnsigned"> </ModalPendency>
    <ModalInfoBot :visibleModalInfoBot="isModalInfoBotVisible" @close="closeModalInfoBot"
      :protocolInfoPopup="protocolInfo" :enableButton="true"> </ModalInfoBot>
    <div class="row mt-5">
      <div class="col-3"></div>
      <div class="col-1 d-flex justify-content-end">
        <div style="position: relative; display: inline-block;">
          <div v-if="imageUrl != null">
            <img class="rounded-circle circle-img" alt="imagem" :src="imageUrl" @click="handleImageClick" />
          </div>
          <div v-else>
            <img class="rounded-circle circle-img" alt="imagem" src="../assets/email_photo.png" />
          </div>
          <font-awesome-icon :icon="['fas', 'pen-to-square']" class="edit-img" @click="handleImageClick"
            style="color: #37E105; position: absolute; bottom: 0; right: 0; margin-bottom: 10px; margin-right: 10px; z-index: 1;" />
        </div>
      </div>
      <div class="col-4">
        <div v-if="User">
          <h5 class="text-font">{{ User.name }}</h5>
        </div>
        <div v-if="valueEnable === true">
          <p class="text-font-regular" style="margin: 0;">Ativo</p>
        </div>
        <div v-else>
          <p class="text-font-regular" style="margin: 0;">Inativo</p>
        </div>
        <div v-if="User">
          <div v-if="User.user_cess_access_info != null">
            <div class="container-signature">
              <p class="text-font-regular" style="margin-top: 2px;">Expiração Assinatura: {{ formatDate() }}</p>
              <div v-if="verifySignatureRequired()">
                <button class="btn-signature" @click="openModalSignatureRequired">Renovar</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-1">
        <Toggle v-model="valueEnable" @change="changeStatus(valueEnable)" />
      </div>
      <div class="col-3"></div>
    </div>
    <div v-if="isLoading === true">
      <div class="conteiner mt-5 mb-5">
        <div class="row mt-5">
          <div class="row mt-4">
            <div class="col-2"></div>
            <div class="col-8">
              <div class="centered-container">
                <div class="loading"></div>
              </div>
              <p class="text-font-regular" style="margin-top: 10px; text-align: center">
                Carregando...
              </p>
            </div>
            <div class="col-2"></div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="conteiner mt-5 mb-5">
        <!-- Pendencias -->
        <div v-if="ReportsUnsigned.length > 0">
          <div class="row mt-5">
            <div class="col-1"></div>
            <div class="col-10">
              <div v-if="ReportsUnsigned.length > 0">
                <h4 class="text-font">
                  Pendências <span class="circle-number">{{ ReportsUnsigned.length }}</span>
                </h4>
              </div>
            </div>
            <div class="col-1"></div>
          </div>
          <div v-if="ReportsUnsigned.length > 0">
            <ul style="list-style-type: none">
              <li v-for="report in ReportsUnsigned" v-bind:key="report.id">
                <div class="row mt-4">
                  <div class="col-1"></div>
                  <div class="col-10">
                    <div class="card">
                      <div class="card-body card-border-pendency">
                        <img src="../assets/alert-triangulo-red.png" alt="ic_cam" />
                        <h4 class="card-title setup-text-card-title" style="margin-top: 5px;">
                          Você possui uma pendência
                        </h4>
                        <p class="card-text setup-text-card">
                          Verificamos a presença de documentos não assinados em seu histórico.
                        </p>
                        <h6 style="margin-bottom: 0; margin-top: 5px; color: #777">
                          {{ report.protocol_update_info.protocol_info.company }}
                        </h6>
                        <h6 style="margin-bottom: 0; color: #777">
                          Paciente:
                          {{
      formatName(report.protocol_update_info.protocol_info.patient_info.name)
    }}
                        </h6>
                        <div class="mt-4"></div>
                        <div class="d-flex justify-content-between">
                          <p></p>
                          <button class="btn btn-secondary_pep" @click="showModalPendency(report)"
                            :disabled="!valueEnable">RESOLVER</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-1"></div>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <!-- Meus Casos -->
        <div class="row mt-5">
          <div class="col-1"></div>
          <div class="col-10">
            <div v-if="verifyReports()">
              <h4 class="text-font">
                Meus Casos <span class="circle-number">{{ Reports.length }}</span>
              </h4>
            </div>
            <div v-else>
              <h4 class="text-font">Meus Casos</h4>
            </div>
          </div>
          <div class="col-1"></div>
        </div>
        <div v-if="verifyReports()">
          <ul style="list-style-type: none">
            <li v-for="report in Reports" v-bind:key="report.id">
              <div class="row mt-4">
                <div class="col-1"></div>
                <div class="col-10">
                  <div class="card">
                    <div class="card-body card-border-unavailable">
                      <div class="input-balloon" v-show="report.isBalloonOpen" @click="closeBalloonReport(report)">
                        <button class="close-btn" @click="closeBalloonReport(report)">X</button>
                        <p><b>Hora início:</b> {{ report.protocol_update_info.created_at }}</p>
                        <div v-if="report.protocol_update_info.protocol_update_traces.length > 0">
                          <div v-for="trace in report.protocol_update_info.protocol_update_traces"
                            v-bind:key="trace.created_at">
                            <div>
                              <p style="margin-bottom: 0;"><b>Evento:</b> Dr. {{ trace.specialist_name }} {{
      trace.description }} - {{
      trace.created_at
    }}</p>
                              <div v-if="trace.reason != null">
                                <p style="margin-bottom: 0;"><b>Motivo:</b> {{ trace.reason }}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="container-tittle-card">
                        <h5 class="card-title setup-text-card-title">
                          {{ report.protocol_update_info.protocol_info.company }}
                        </h5>
                        <img src="../assets/information_tracking.png" style="cursor: pointer;"
                          v-show="!report.isBalloonOpen" @click="openBalloonReport(report)" />
                      </div>
                      <p class="card-text setup-text-card">
                        Paciente:
                        {{
      formatName(report.protocol_update_info.protocol_info.patient_info.name)
    }}
                      </p>
                      <div class="mt-4"></div>
                      <div class="d-flex justify-content-between">
                        <div class="d-flex">
                          <button class="btn-primary-time">
                            {{ diffTime(report.protocol_update_info.created_at) }}
                          </button>
                          <div v-if="report.protocol_update_info.AVC_protocol == 1">
                            <button class="btn-primary-avc" style="margin-left: 5px; height:100%;">
                              <img src="../assets/alert_protocol.png">
                              <span class="d-none d-md-inline" style="margin-left: 5px;">Protocolo</span> AVC
                            </button>
                          </div>
                        </div>
                        <button class="btn btn-secondary_pep" @click="openAttendanceFormInfo(report)"
                          :disabled="!valueEnable">ATENDER</button>
                      </div>

                    </div>
                  </div>
                </div>
                <div class="col-1"></div>
              </div>
            </li>
          </ul>
        </div>
        <div v-else>
          <div class="row mt-4">
            <div class="col-2"></div>
            <div class="col-8">
              <img src="../assets/empty-folder.png" width="50" height="50" style="display: block; margin: 0 auto;">
              <p class="text-font-regular" style="text-align: center; margin-top: 15px">
                Não há casos ativos em antendimento.
              </p>
            </div>
            <div class="col-2"></div>
          </div>
        </div>

        <!-- Em Fila -->
        <div id="protocol_queue">
          <div class="row mt-5">
            <div class="col-1"></div>
            <div class="col-10">
              <div v-if="verifyProtocols()">
                <h4 class="text-font">
                  Em Fila <span class="circle-number">{{ Protocols.length }}</span>
                </h4>
              </div>
              <div v-else>
                <h4 class="text-font">Em Fila</h4>
              </div>
            </div>
            <div class="col-1"></div>
          </div>

          <div v-if="verifyProtocols()">
            <ul style="list-style-type: none">
              <li v-for="protocol in Protocols" v-bind:key="protocol.id">
                <div class="row mt-4">
                  <div class="col-1"></div>
                  <div class="col-10">
                    <div class="card">
                      <div class="card-body card-border-available">
                        <div class="input-balloon" v-show="protocol.isBalloonOpen">
                          <button class="close-btn" @click="closeBalloon(protocol)">X</button>
                          <p><b>Hora início:</b> {{ protocol.created_at }}</p>
                          <div v-if="protocol.protocol_update_traces.length > 0">
                            <div v-for="trace in protocol.protocol_update_traces" v-bind:key="trace.created_at">
                              <div>
                                <p style="margin-bottom: 0;"><b>Evento:</b> Dr. {{ trace.specialist_name }} {{
      trace.description }} - {{ trace.created_at
                                  }}</p>
                                <div v-if="trace.reason != null">
                                  <p style="margin-bottom: 0;"><b>Motivo:</b> {{ trace.reason }}</p>
                                </div>
                              </div>
                              <p></p>
                            </div>
                          </div>
                        </div>
                        <div class="container-tittle-card">
                          <h5 class="card-title setup-text-card-title">
                            {{ protocol.protocol_info.company }}</h5>
                          <img src="../assets/information_tracking.png" style="cursor: pointer;"
                            v-show="!protocol.isBalloonOpen" @click="openBalloon(protocol)" />
                        </div>
                        <p class="card-text setup-text-card">
                          Especialidade: {{ protocol.protocol_info.specialty }}
                        </p>
                        <p class="card-text setup-text-card">
                          Responsável:
                          {{ formatName(protocol.responsible_info.name) }}
                        </p>
                        <p class="card-text setup-text-card">
                          Paciente:
                          {{
      formatName(protocol.protocol_info.patient_info.name)
    }}
                        </p>
                        <p class="card-text setup-text-card">
                          Tipo: {{ protocol.case_type }}
                        </p>
                        <p class="card-text setup-text-card">
                          Local: {{ protocol.case_location }}
                        </p>
                        <p class="card-text setup-text-card">
                          Prioridade: {{ protocol.case_priority }}
                        </p>

                        <div class="mt-4"></div>
                        <div class="d-flex justify-content-between">
                          <div class="d-flex">
                            <button class="btn-primary-time">
                              {{ diffTime(protocol.created_at) }}
                            </button>
                            <div v-if="protocol.AVC_protocol == 1">
                              <button class="btn-primary-avc" style="margin-left: 5px; height:100%;">
                                <img src="../assets/alert_protocol.png">
                                <span class="d-none d-md-inline" style="margin-left: 5px;">Protocolo</span> AVC
                              </button>
                            </div>
                          </div>
                          <button class="btn btn-secondary_pep" @click="showModalInfoBot(protocol)"
                            :disabled="!valueEnable">VER CASO</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-1"></div>
                </div>
              </li>
            </ul>
          </div>
          <div v-else>
            <div class="row mt-4">
              <div class="col-2"></div>
              <div class="col-8">
                <img src="../assets/empty-folder.png" width="50" height="50" style="display: block; margin: 0 auto;">
                <p class="text-font-regular" style="text-align: center; margin-top: 15px">
                  Não há casos ativos em espera.
                </p>
              </div>
              <div class="col-2"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div v-if="verifyProtocols()">
    <div class="floating-button btn-secondaryfooter-pep text-font-footer">
      <a id="link" style="text-decoration: none;" href="#protocol_queue">
        <h5 class="text-font-footer">
          Em Fila <span class="circle-number" style="margin-bottom: 5px;">{{ Protocols.length }}</span>
        </h5>
      </a>
    </div>
  </div>


  <div class="row" style="margin-bottom: 100px;">
    <div class="col-2"></div>
    <div class="col-8">
      <div class="attentive-buttons">
        <button class="btn-back-queue" @click="reloadPage()">ATUALIZAR PÁGINA</button>
      </div>
    </div>
    <div class="col-2"></div>
  </div>
</template>

<style src="@vueform/toggle/themes/default.css"></style>

<style scoped>
.close-btn {
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 5px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.input-balloon {
  position: relative;
  padding: 15px;
  background: rgb(245, 245, 242);
  z-index: 99;
  margin-bottom: 10px;
}

.input-balloon::before {
  position: absolute;
  left: 20px;
  top: -15px;
  display: block;
  width: 0;
  height: 0;
  border-right: 15px solid transparent;
  border-bottom: 15px solid #fff;
  border-left: 15px solid transparent;
}

.container-tittle-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.btn-back-queue {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #6200EE;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border: 1px solid #6200ee;
  border-radius: 20px;
  padding: 10px;
  width: 300px;
  cursor: pointer;
  margin: 0 auto;
}

.attentive-buttons {
  margin-top: 25px;
  display: flex;
  justify-content: center;
}

.container-signature {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.margin-end-card {
  margin-top: 8px;
}

.setup-text-card-title {
  color: #777777;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}

.setup-text-card {
  color: #777777;
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  margin: 0;
  padding: 0;
}

.setup-text-card-specialist {
  color: #6200ee;
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  margin: 0;
  padding: 0;
}

.btn-primary-time {
  background-color: #ae1f38;
  color: #ffffff;
  border-radius: 10px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  border: none;
}

.btn-primary-avc {
  align-items: left;
  background-color: #E6DE0E;
  color: #ffffff;
  border-radius: 10px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  border: none;
}


.btn-secondary_pep {
  background-color: #ffffff;
  color: #6200ee;
  padding-left: 5px;
  border: none;
  background: none;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}

.btn-secondaryfooter-pep {
  background-color: #6495ED;
  border: 1px;
  border-radius: 20px;
  font-family: "Roboto", sans-serif;
  width: 250px;
  align-items: center;
}

.card-border-available {
  border-left: 8px solid #37e105;
}

.card-border-unavailable {
  border-left: 8px solid #e1ee26;
}

.card-border-pendency {
  border-left: 8px solid rgb(246, 8, 16);
}

.search-bar {
  display: flex;
  align-items: center;
  background-color: #f0f0f0;
  border-radius: 20px;
  padding: 10px;
}

.search-bar i {
  margin-right: 10px;
}

.search-bar input[type="search"] {
  flex: 1;
  border: none;
  outline: none;
}

.background-search {
  background-color: #f0f0f0;
}

.text-font {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #777777;
}

.text-font-footer {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #ffff;
  margin-top: 12px;
}

.text-font-regular {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  color: #777777;
}

.circle-img {
  width: 80px;
  height: 80px;
}

.edit-img:hover {
  cursor: pointer;
}

.circle-img:hover {
  cursor: pointer;
}

.btn-signature {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #6200EE;
  display: flex;
  align-items: center;
  background-color: #fff;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  margin: 0 auto;
}

.floating-button {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 12px 24px;
  color: white;
  border: none;
  border-radius: 24px;
  font-size: 16px;
  cursor: pointer;
}

.circle-number {
  width: 50px;
  height: 50px;
  padding: 0;
  background-color: #AE1F38;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
}

.loading {
  width: 50px;
  height: 50px;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.centered-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>

<script>
import ModalChangePhoto from "../components/ModalChangePhoto.vue";
import ModalInfoBot from "../components/ModalInfoBot.vue";
import ModalPendency from "../components/ModalPendency.vue";
import Toggle from "@vueform/toggle";
import { mapGetters, mapActions } from "vuex";
export default {
  name: `Protocols`,
  components: {
    Toggle,
    ModalChangePhoto,
    ModalInfoBot,
    ModalPendency
  },
  data() {
    return {
      isLoading: true,
      valueEnable: false,
      isModalVisible: false,
      isModalPendencyVisible: false,
      isModalInfoBotVisible: false,
      reportUnsigned: Object,
      protocolInfo: Object,
      imageUrl: null,
      isFocused: false,
    };
  },
  async created() {    
    if (!this.isAuthenticated()) {
      this.$router.push("/login");
    } else {
      try {
        await this.GetEnable();
        this.imageUrl = this.User.photo_url
        this.valueEnable = this.Enable;
        await this.GetProtocols();
        await this.GetReports();
        await this.GetReportsUnsigned();
        this.isLoading = false;
      }
      catch {
        this.$toast.error('Ocorreu um erro, tente novamente.', {
          timeout: 3000,
          closeOnClick: true
        });
      }
    }
  },
  async mounted() {
  },
  computed: {
    ...mapGetters({ Reports: "StateReports", ReportsUnsigned: "StateReportsUnsigned", Protocols: "StateProtocols", User: "StateUser", Enable: "StateEnable" }),
  },
  methods: {
    ...mapActions(["SetEnable"]),
    ...mapActions(["SetReport"]),
    ...mapActions(["SetReport"]),
    ...mapActions(["SetProtocol"]),
    ...mapActions(["GetProtocols"]),
    ...mapActions(["GetReports"]),
    ...mapActions(["GetReportsUnsigned"]),
    ...mapActions(["GetEnable"]),
    ...mapGetters(["isAuthenticated"]),
    ...mapActions(['openModalSignatureRequired']),
    openBalloon(protocol) {
      this.Protocols.forEach(p => {
        p.isBalloonOpen = false
      });
      protocol.isBalloonOpen = true;
    },
    closeBalloon(protocol) {
      protocol.isBalloonOpen = false;
    },
    openBalloonReport(report) {
      this.Reports.forEach(r => {
        r.isBalloonOpen = false
      });
      report.isBalloonOpen = true;
    },
    closeBalloonReport(report) {
      report.isBalloonOpen = false;
    },
    acaoConcluidaHandler() {
      this.closeModalChangePhoto();
      this.reloadPage();
    },
    reloadPage() {
      window.location.reload();
    },
    handleImageClick() {
      this.showModalChangePhoto();
    },
    verifyProtocols() {
      if (this.Protocols != null) {
        if (this.Protocols.length > 0) {
          return true;
        }
      }
      return false;
    },
    verifyReports() {
      if (this.Reports != null) {
        if (this.Reports.length > 0) {
          return true;
        }
      }
      return false;
    },
    formatPhone(phone) {
      const ddd = phone.substring(2, 4);
      const first = phone.substring(4, 9);
      const second = phone.substring(9);
      return `${ddd} ${first}-${second}`;
    },
    diffTime(data) {
      const dateA = new Date(
        parseInt(data.substring(6, 10)),
        parseInt(data.substring(3, 5)) - 1,
        parseInt(data.substring(0, 2)),
        parseInt(data.substring(11, 13)),
        parseInt(data.substring(14, 16))
      );
      const dataNow = new Date();
      const diff = dataNow.getTime() - dateA.getTime();
      const hours = Math.floor(diff / (1000 * 60 * 60));
      const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
      if (hours > 1) {
        return `${hours}h:${minutes}m`;
      }
      else {
        return `${minutes}m`;
      }
    },
    formatName(name) {
      let words = name.toLowerCase().split(" ");
      for (let i = 0; i < words.length; i++) {
        let word = words[i];
        words[i] = word.charAt(0).toUpperCase() + word.slice(1);
      }
      return words.join(" ");
    },
    showModalPendency(paramReportUnsigned) {
      this.reportUnsigned = paramReportUnsigned;
      this.isModalPendencyVisible = true;
    },
    closeModalPendency() {
      this.isModalPendencyVisible = false;
    },
    showModalChangePhoto() {
      this.isModalVisible = true;
    },
    closeModalChangePhoto() {
      this.isModalVisible = false;
    },
    closeModalInfoBot() {
      this.isModalInfoBotVisible = false;
    },
    showModalInfoBot(paramProtocol) {
      this.protocolInfo = paramProtocol;
      this.isModalInfoBotVisible = true;
    },
    async openAttendanceForm(protocol) {
      try {
        await this.SetProtocol(protocol)
        this.$router.push({ name: 'Report' });
      }
      catch {
        this.$toast.error('Ocorreu um erro, tente novamente.', {
          timeout: 3000,
          closeOnClick: true
        });
      }
    },
    async openAttendanceFormInfo(report) {
      await this.SetReport(report);
      this.$router.push({ name: 'Report' });
    },
    async changeStatus(enable) {
      try {
        await this.SetEnable(enable === true ? 1 : 0)
        this.valueEnable = enable
        if (enable === true) {
          this.$toast.success('Você está disponível para atender', {
            timeout: 3000,
            closeOnClick: true
          });
        }
        else {
          this.$toast.error('Você está indisponível para atender', {
            timeout: 3000,
            closeOnClick: true
          });
        }
      }
      catch {
        this.$toast.error('Ocorreu um erro, tente novamente.', {
          timeout: 3000,
          closeOnClick: true
        });
      }
    },
    formatDate() {
      if (this.User.user_cess_access_info != null) {
        var dateString = this.User.user_cess_access_info.expired_at
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const formattedDate = `${day}/${month}/${year} ${hours}:${minutes}`;
        return formattedDate;
      }
    },
    verifySignatureRequired() {
      const dateActual = new Date();
      if (this.User.user_cess_access_info != null) {
        const dateExpired = new Date(this.User.user_cess_access_info.expired_at);
        if (dateActual > dateExpired) {
          return true
        }
        return false
      }
      else {
        return false
      }
    },
  },
};
</script>
